import React from 'react'
import Layout from '../components/Layout'
import Header from '../components/Header'
import Hero from '../components/Hero'
import { Link as InternalLink } from 'react-scroll'

const ServicesPageContent = () => {

  const services = [
    {key: 'develop', name: '受託開発', body: '優秀なメンバーによるソフトウェアの受託開発'},
    {key: 'support', name: '他社支援・協業', body: 'IT化、フルリモート/フルフレックス導入、海外進出支援等'},
    {key: 'education', name: 'Moba Pro IT English', body: 'ITエンジニア向けオンライン英会話レッスンサービス'},
    {key: 'web-service', name: 'Toro', body: '初心者PMサポートサービス'},
  ]

  return (
    <div className="main">
      <section className="section section--gray">
        <div className="section__inner">
          <div className="service-menu">
            <ul className="service-menu__list">
              {services.map((service, index) => {
                return (
                  <li className="service-menu__list-item" key={service.key}>
                    <InternalLink
                      to={service.key}
                      smooth={true}
                      duration={500}
                      className="service-menu__link"
                    >
                      <div className="service-menu__icon">
                        <div className="service-menu__img-wrapper">
                          <img
                            className="service-menu__img"
                            src={`../../image/common/services/ico_service_${service.key}.svg`}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="service-menu__text">
                        <p className="service-menu__name">{service.name}</p>
                        <p className="service-menu__body">{service.body}</p>
                      </div>
                    </InternalLink>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </section>

      <section className="section section--service" id="service">
        <div className="section__inner">
          <div className="service">
            <div className="service-page__content service-page__content--develop clearfix" id="develop">
              <div className="service-page__bg service-page__bg--develop">
                <img src="../../image/common/services/ico_service_develop.svg" alt="" className="service-page__bg-img" />
              </div>
              <div className="service-page__title">受託開発</div>
              <img src="../../image/top/img_border_blue.png" alt="" className="service-page__border service-page__border--right" />
              <div className="service-page__vis service-page__vis--left">
                <img src="../../image/top/img_develop.jpg" alt="" className="service-page__img" />
                {/* <img src="../../image/top/img_dot_blue.png" alt="" className="service-page__dot service-page__dot--left" />  */}
              </div>
              <div className="service-page__text service-page__text--right">
                <p className="service-page__body service-page__body--right">Webサービス／スマートフォンアプリ／大規模データ処理など、優秀なメンバーによるソフトウェア受託開発を行っています。小〜中規模案件、納期が短いもの関わらず柔軟に対応します。まずはお気軽にご相談ください。</p>
                <p className="service-page__body--bold service-page__body--right">得意領域</p>
                <p className="service-page__body service-page__body--right"><a href="https://blog.mobalab.net/2019/06/03/our-strengths-ver-2019/" target="_blank" rel="noreferrer" className="service-page__text-link">ブログ</a>参照</p>
                <div className="service-page__btn-area service-page__btn-area--develop service-page__btn-area--left">
                  <a className="btn-blue" href="/strengths/">３つの強み</a>
                  <a className="btn-blue" href="/works/">実績紹介</a>
                  <a className="btn-blue" href="https://blog.mobalab.net/inquiry/" target="_blank" rel="noreferrer">お問い合わせ</a>
                </div>
              </div>
            </div>

            <div className="service-page__content service-page__content--smb clearfix" id="support" name="support">
              <div className="service-page__bg service-page__bg--smb">
                <img src="../../image/common/services/ico_service_support.svg" alt="" className="service-page__bg-img" />
              </div>
              <div className="service-page__title">他社支援・協業</div>
              <img src="../../image/top/img_border_pink.png" alt="" className="service-page__border service-page__border--left" />
              <div className="service-page__vis service-page__vis--right">
                <img src="../../image/top/img_smb.jpg" alt="" className="service-page__img" />
                {/* <img src="../../image/top/img_dot_pink.png" alt="" className="service-page__dot service-page__dot--right" />  */}
              </div>
              <div className="service-page__text service-page__text--left">
                <p className="service-page__body--bold service-page__body--left">IT化、フルリモート/フルフレックス導入、海外進出支援等</p>
                <p className="service-page__body service-page__body--left">創業当時から柔軟な働き方を推進し、メンバーは好きな場所からリモートワークを行っています。これまで培ってきたこれらの経験やノウハウで、リモートワーク導入のご支援、また、同じような価値観を持つ企業様との協業活動を行っています。</p>
                <div className="service-page__btn-area">
                  <a className="btn-pink" href="https://blog.mobalab.net/inquiry/" target="_blank" rel="noreferrer">お問い合わせ</a>
                </div>
                <p className="service-page__body--bold service-page__body--left">技術コンサル・セミナー</p>
                <p className="service-page__body service-page__body--left">弊社のエンジニア、コンサルタントが、お客様の課題に応じて、コンサルティング・セミナーを行います。リモートワークに関する問題、炎上プロジェクトの立て直しなど、お気軽にご相談下さい。</p>
                <div className="service-page__btn-area">
                  <a className="btn-pink" href="https://blog.mobalab.net/inquiry/" target="_blank" rel="noreferrer">お問い合わせ</a>
                </div>
              </div>
            </div>

            <div className="service-page__content service-page__content--education clearfix" id="education">
              <div className="service-page__bg service-page__bg--education">
                <img src="../../image/common/services/ico_service_education.svg" alt="" className="service-page__bg-img" />
              </div>
              <div className="service-page__title">Moba Pro IT English</div>
              <img src="../../image/top/img_border_green.png" alt="" className="service-page__border service-page__border--right" />
              <div className="service-page__vis service-page__vis--left">
                <img src="../../image/top/img_education.jpg" alt="" className="service-page__img" />
                {/* <img src="../../image/top/img_dot_green.png" alt="" className="service-page__dot service-page__dot--right" />  */}
              </div>
              <div className="service-page__text service-page__text--right">
                <p className="service-page__body--bold service-page__body--right">ITエンジニア向けオンライン英会話レッスンサービス</p>
                <p className="service-page__body service-page__body--right">IT業界、ソフトウェア業界での業務に必要な英語習得に特化した英語コースです。オンラインレッスン、自習サポート、実力判定テスト、実プロジェクトのサポート（オプション）などを行っています。</p>
                <div className="service-page__btn-area service-page__btn-area--left">
                  <a className="btn-green" href="https://moba-pro.com/" target="_blank">モバプロのサイトへ</a>
                </div>
              </div>
            </div>

            <div className="service-page__content service-page__content--web clearfix" id="web-service">
              <div className="service-page__bg service-page__bg--web">
                <img src="../../image/common/services/ico_service_web-service.svg" alt="" className="service-page__bg-img" />
              </div>
              <div className="service-page__title">Toro（開発中）</div>
              <img src="../../image/top/img_border_purple.png" alt="" className="service-page__border-web service-page__border--left" />
              <div className="service-page__vis-web service-page__vis--right">
                <img src="../../image/services/img_toro.png" alt="" className="service-page__img-web service-page__img-web-toro" />
                {/* <img src="../../image/top/img_dot_purple.png" alt="" className="service-page__dot service-page__dot--left" />  */}
              </div>
              <div className="service-page__text-web service-page__text--left">
                <p className="service-page__body--bold service-page__body--left">初心者PMサポートサービス</p>
                <p className="service-page__body service-page__body--left">「AIの助けを借りて、初心者PMをスケジュール遅延・予算超過から防ぐ」ためのサービスです。プロジェクトの進行状況を人口知能 (AI) が判断し、今やるべきことをアドバイスし、初心者プロジェクトマネージャーをサポートします。</p>
                <div className="service-page__btn-area">
                  <a className="btn-purple" href="http://commet-pm-teaser-ja.strikingly.com/" target="_blank" rel="noreferrer">Toro（仮）の詳細を見る</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

const ServicesPage = (props) => (
  <Layout
    title="サービス | 株式会社もばらぶ"
    description="株式会社もばらぶの事業内容です。受託開発、開発を効率化するWebサービス、ITエンジニア向け英語教育などを行っています。"
    pathName={props.location.pathname}
  >
    <Hero
      title="SERVICES"
      titleJa="事業内容"
      body="「働く場所・時間帯を、働く人が自由に選べるような社会にする」を目標にサービスを展開しています。"
    />
    <Header />
    <ServicesPageContent />
  </Layout>
)

export default ServicesPage